.custom-phone-input {
  height: 48px !important;
  border: 1px solid #d8d8d8;
  border-width: 1px !important;
  overflow: hidden;
}

.custom-phone-input:hover {
  border: 1px solid #aeaeae !important; /* Change border color on hover */
}

.custom-phone-input:active {
  border: 1px solid #512acc !important; /* Change border color on hover */
}

.custom-phone-input:focus-within {
  border: 1px solid #512acc !important; /* Change border color on hover */
}

.custom-phone-input-error {
  height: 48px !important;
  border: 1px solid #f75443;
  border-width: 1px !important;
  overflow: hidden;
}

.custom-phone-input-error .PhoneInputInput {
  padding: 0px 0px 0px 12px !important; /* Removes padding from the input field */
  height: 48px;
}

.custom-phone-input-error .PhoneInputCountry {
  height: 48px;
}

.custom-phone-input-error:hover {
  border: 1px solid #aeaeae !important; /* Change border color on hover */
}

.custom-phone-input-error:active {
  border: 1px solid #512acc !important; /* Change border color on hover */
}

.custom-phone-input-error:focus-within {
  border: 1px solid #512acc !important; /* Change border color on hover */
}
